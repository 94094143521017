<script setup lang="ts">
import { format, parse, sub } from 'date-fns'
import { Constants } from '@yescapa-dev/ysc-api-js/legacy'
import type { HourFrom, HourTo, KmOption, CamperState, UserDetailsResponse } from '@yescapa-dev/ysc-api-js/legacy'
import type { Product } from 'schema-dts'
import { BOOKING_PRICE_ERROR_CODES, CAMPER_STATES, TRACKING_EVENT_TYPES } from '@yescapa-dev/ysc-api-js/modern'
import type { RouteLocationRaw } from '#vue-router'
import { imageTypeOrder } from '~/utils/camper'
import { getSimilarQuery } from '~/utils/vehicle'
import { YSC_API_CAMPER_ERROR, YSC_API_CAMPER_PRICE_ERROR, YSC_API_USER_ERROR, YSC_API_VEHICLE_ERROR, YSC_API_WISHLIST_ERROR } from '~/utils/error/YscErrorClasses'
import type { AppModalCamperFeature } from '~/components/App/Modal/AppModalCamperFeatures.vue'
import { CAMPER_SEE_MORE_PICTURES_EVENT } from '~/utils/analytics/camper'
import type { CamperInsuranceBlock } from '~/types/camperType'
import type { BookingRequestForm } from '~/composables/useBookingRequest'
import { YscApiCamperPriceError } from '~/utils/error/YscApiCamperPriceError'
import { CAMPER_PRICE_INVALID_DOCUMENTS, CAMPER_PRICE_NOT_AVAILABLE } from '~/utils/error/errorFingerprints'
import { YscApiError } from '~/utils/error/YscApiError'

definePageMeta({
  middleware: [
    async (to, from) => {
      const nuxtApp = useNuxtApp()
      const { fetchCamper, fetchCurrentCamperAvailabilities } = useCamperStore()
      const { user } = useUserStore()
      const { camper, currentCamperAvailabilities: availabilities } = storeToRefs(useCamperStore())
      const camperId = paramValueToNumber(to.params.id)
      if (!camperId) {
        throw createError({ statusCode: 404, message: 'Invalid camper id' })
      }
      try {
        await fetchCamper({ id: camperId, params: { user_action: TRACKING_EVENT_TYPES.CAMPER_VIEWED } })
      }
      catch (e) {
        let qualifiedError = e
        let statusCode = 404
        if (e instanceof Error) {
          qualifiedError = await nuxtApp.$errorManager({ e, name: YSC_API_CAMPER_ERROR })
          if (qualifiedError instanceof YscApiError && qualifiedError.apiStatus === 410) {
            statusCode = 410
          }
        }
        throw createError({ statusCode, cause: qualifiedError, message: 'Error fetching camper' })
      }

      try {
        await fetchCurrentCamperAvailabilities()
      }
      catch (e) {
        let qualifiedError = e
        if (e instanceof Error) {
          // If the camper is not bookable ( deleted or lack of documents for example ) availabilities return a 404 error but we don't want it to block the page or send to sentry.
          if (camper.value?.state === CAMPER_STATES.BOOKABLE || e.status !== 404) {
            qualifiedError = nuxtApp.$errorManager({ e, name: YSC_API_CAMPER_ERROR })
            throw createError({ statusCode: 404, cause: qualifiedError, message: 'Error fetching camper availabilities' })
          }
        }
      }

      if (!camper.value) {
        // If camper is null here there is a problem with the store, it should have thrown on fetchCamper.
        throw createError({ statusCode: 500, message: 'Runtime error - no camper in store' })
      }

      // Do not show unpublished camper, except for the owner
      if (!camper.value?.published && camper.value?.vehicle_owner_pk !== user?.id && !user?.is_staff) {
        throw createError({ statusCode: 404, message: 'Unpublished camper' })
      }

      const { query } = to

      if (!Object.keys(query).length) {
        return
      }

      if (
        import.meta.client
          && from.name === 'campers-id'
          && ((!from.query.more && to.query.more) || (from.query.more && !to.query.more))
      ) {
        return
      }

      const { mustRedirect, nextQuery } = await validateCamperQuery({
        query,
        availabilities: availabilities.value,
        camper: camper.value,
      })

      if (mustRedirect) {
        return nuxtApp.runWithContext(() => navigateTo({ name: 'campers-id', params: to.params, query: nextQuery }))
      }
    },
  ],
})
const { runWithContext } = useNuxtApp()

const route = useRoute()
const router = useRouter()
const camperId = paramValueToNumber(route.params.id)
if (!camperId) {
  // should have been thrown in middleware
  throw createError({ statusCode: 404, message: 'Invalid camper id' })
}

const { localeProperties: { yscCode } } = useLocaleProperties()
const { t, n } = useI18n()

const { camper, vehicle, currentCamperAvailabilities: availabilities } = storeToRefs(useCamperStore())
const { fetchVehicle, fetchCurrentCamperAvailabilities } = useCamperStore()

const { user, isLoggedIn } = storeToRefs(useUserStore())

const { isCamperInWishlist, fetchWishlists } = useWishlistsStore()

const { fetchPrices, resetPrices } = useBookingsGuestStore()
const { request } = storeToRefs(useBookingsGuestStore())

const { $errorManager } = useErrorManager()
const { $api } = useYscApi()
const {
  public: {
    devFlag: { allianzDeSpecificContent },
  },
} = useRuntimeConfig()
const formatConsumptionEstimate = useFormatConsumptionEstimate()
const { getTranslatedCamperOptionRecurrence } = useGetTranslatedCamperOptionRecurrence()

const bookingRequestForm: Ref<BookingRequestForm> = ref({
  dateRange: {
    start: null,
    end: null,
  },
  hours: {
    to: null,
    from: null,
  },
  kilometers: null,
})

const queryDateFrom = computed(() => queryValueToString(route.query.date_from))
const queryDateTo = computed(() => queryValueToString(route.query.date_to))

const { data, error } = await useAsyncData(`page-camper-${route.query.id}`, async () => {
  if (!camper.value) {
    throw createError({ statusCode: 500 }) // camper should be initialized from the middleware
  }

  const { hour_from = null, hour_to = null } = route.query
  const { DEPARTURE_MORNING, DEPARTURE_AFTERNOON, ARRIVAL_MORNING, ARRIVAL_AFTERNOON } = Constants.BOOKINGS.HOURS

  // preset dates if it's a range
  const dateRange: { start: Date | null, end: Date | null } = { start: null, end: null }
  if (queryDateFrom.value && queryDateTo.value) {
    dateRange.start = parse(queryDateFrom.value, 'yyyy-MM-dd', new Date())
    dateRange.end = parse(queryDateTo.value, 'yyyy-MM-dd', new Date())
  }

  // preset hours with potential forced values
  let defaultHourFrom: HourFrom = hour_from !== null ? +hour_from as HourFrom : (camper.value.half_day_activated ? DEPARTURE_AFTERNOON : DEPARTURE_MORNING),
    defaultHourTo: HourTo = hour_to !== null ? +hour_to as HourTo : (camper.value.half_day_activated ? ARRIVAL_MORNING : ARRIVAL_AFTERNOON)

  if (camper.value?.has_forced_morning_departure) {
    defaultHourFrom = DEPARTURE_MORNING
  }
  else if (camper.value?.has_forced_afternoon_departure) {
    defaultHourFrom = DEPARTURE_AFTERNOON
  }

  if (camper.value?.has_forced_morning_arrival) {
    defaultHourTo = ARRIVAL_MORNING
  }
  else if (camper.value?.has_forced_afternoon_arrival) {
    defaultHourTo = ARRIVAL_AFTERNOON
  }

  const defaultKilometer = queryValueToNumber(validateQueryKilometerParameters({ query: route.query, camper: camper.value }).nextQuery.kilometers)

  const bookingRequestDataForm = {
    dateRange,
    hours: { from: defaultHourFrom, to: defaultHourTo },
    kilometers: defaultKilometer,
  }

  const fetchPricesIfNeeded = async () => {
    if (!dateRange.start || !dateRange.end) {
      return Promise.resolve()
    }
    const defaultBornedOn = user.value?.borned_on ? new Date(user.value.borned_on) : sub(new Date(), { years: 23, days: 1 })

    return await fetchPrices({
      id: camperId,
      params: {
        date_from: format(dateRange.start, 'yyyy-MM-dd'),
        date_to: format(dateRange.end, 'yyyy-MM-dd'),
        hour_from: defaultHourFrom,
        hour_to: defaultHourTo,
        kilometers: (defaultKilometer ?? 100) as KmOption,
        borned_on: format(defaultBornedOn, 'yyyy-MM-dd'),
        insurance: camper.value?.insurance_id,
      },
    })
  }

  const [ownerRequest, wishlistRequest, vehicleRequest, camperPriceRequest] = await Promise.allSettled([
    $api.users.getById(camper.value.vehicle_owner_pk),
    fetchWishlists(),
    fetchVehicle(),
    fetchPricesIfNeeded(),
  ])
  if (ownerRequest.status === 'rejected') {
    $errorManager({ e: ownerRequest.reason, name: YSC_API_USER_ERROR })
    throw createError({ statusCode: 404 })
  }
  if (wishlistRequest.status === 'rejected') {
    $errorManager({ e: wishlistRequest.reason, name: YSC_API_WISHLIST_ERROR })
  }
  if (vehicleRequest.status === 'rejected') {
    $errorManager({ e: vehicleRequest.reason, name: YSC_API_VEHICLE_ERROR })
    throw createError({ statusCode: 404 })
  }
  if (camperPriceRequest.status === 'rejected') {
    $errorManager({ e: camperPriceRequest.reason, name: YSC_API_CAMPER_PRICE_ERROR })
    // omit price parameters

    const { date_from, date_to, hour_from, hour_to, kilometers, ...query } = route.query
    await runWithContext(async () => await navigateTo({ name: 'campers-id', params: route.params, query }))
    return
  }
  return { owner: ownerRequest.value as UserDetailsResponse, bookingRequestDataForm }
})

if (error.value) {
  showError(error.value)
}

const owner = computed(() => data.value?.owner)
if (data.value?.bookingRequestDataForm) {
  bookingRequestForm.value = data.value?.bookingRequestDataForm
}

const moreMap = {
  WISHLIST: 'wishlist',
  PHOTOS: 'photos',
  DESCRIPTION: 'description',
  INSURANCE: 'insurance',
  EQUIPMENTS: 'equipments',
  FEATURES: 'features',
  CAMPER_CANCEL_POLICY: 'cancel-policy',
}

const vehicleType = computed(() => {
  if (!camper.value) {
    return null
  }
  const vehicleType = useVehicleType({ type: camper.value.vehicle_type.toLowerCase() })

  return (vehicleType && upperCaseFirstLetter(vehicleType, yscCode)) || null
})

const title = computed(() => camper.value?.is_pro
  ? vehicleType.value
  : t('components.ysc_owner_field.vehicle_of_dynamic', {
    vehicle_type_string: vehicleType.value,
    first_name_string: camper.value?.vehicle_owner_first_name,
  }),
)
const { getTranslatedLink } = useGetTranslatedLink()
const crumbs = computed(() =>
  [
    { name: getTranslatedLink('index'), to: { name: 'index' }, external: true },
    { name: getTranslatedLink('s'), to: { name: 's' } },
    {
      name: title.value,
      to: { name: 'campers-id', params: route.params, query: route.query },
    },
  ],
)

const { getReferentialOptions, getReferentialMaps } = useReferentialStore()
const { referential } = storeToRefs(useReferentialStore())
const currencyMap = getReferentialMaps('currency')

const vehiclePhotos = computed(() => camper.value ? sortArrayByProps(camper.value.picture_set, 'vehicle_image_type', imageTypeOrder) : [])
const vehicleAddress = computed(() =>
  `${vehicle.value?.location.city} (${vehicle.value?.location.zipcode}), ${
    getReferentialOptions('country').find(({ value }) => value === vehicle.value?.location.country)?.text
  }`,
)

const seeAllPhotos = () => {
  const { event } = useGtag()
  event(CAMPER_SEE_MORE_PICTURES_EVENT)
  navigateTo(useGetRouteWith({ query: { ...route.query, more: moreMap.PHOTOS } }))
}

const about = () => {
  switch (vehicle.value?.type) {
    case 1:
      return t('pages.campers.about.lowprofile')
    case 2:
      return t('pages.campers.about.coachbuilt')
    case 3:
      return t('pages.campers.about.aclass')
    case 4:
      return t('pages.campers.about.campervan')
    case 5:
      return t('pages.campers.about.van')
    case 6:
      return t('pages.campers.about.caravan')
    default:
      return t('pages.campers.about.default')
  }
}

const verifiedDocuments = computed(() => {
  const car_registration = camper.value?.documents.find(({ document_type }) => document_type === Constants.DOCUMENTS.TYPE.CAR_REGISTRATION)
  return {
    car_registration: car_registration !== undefined && documentIsValidated(car_registration),
  }
})

const permissions = computed(() => {
  const permissions = {
    canShowMoreDescription: false,
    canTranslateDescription: false,
  }

  permissions.canShowMoreDescription = !!vehicle.value?.descriptions_default_language

  permissions.canTranslateDescription
        = permissions.canShowMoreDescription
        && getTopLevelLocale(yscCode) !== vehicle.value?.descriptions_default_language

  return permissions
})

const noInsurance = computed(() => (camper.value?.regular_insurance.slug === Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG && !vehicle.value?.self_insured) ?? false)

const isAllianzDe = computed(() => camper.value?.insurance_slug === 'allianz-de' || allianzDeSpecificContent)

const is24_7_insurance = computed(() => isAllianzDe.value && vehicle.value?.driving_licence_required === Constants.VEHICLES.DRIVING_LICENCE.C)

const insuranceBlock = computed<CamperInsuranceBlock>(() => {
  // NO INSURANCE
  if (noInsurance.value) {
    return {
      title: t('pages.campers.no_insurance'),
      subtitle: t('pages.campers.no_insurance_included'),
      details: [],
    }
  }

  // SELF INSURED
  if (camper.value?.insurance_slug === Constants.INSURANCES.OWNER_MANAGED_INSURANCE_SLUG) {
    return {
      title: t('pages.campers.insurance_included_self_insured'),
      details: [
        {
          label: t('pages.campers.insurance_details_no_multirisk'),
          description: undefined },
        {
          label: t('pages.campers.insurance_details_assistance'),
          description: t('pages.campers.insurance_details_assistance_description') },
        {
          label: t('pages.campers.insurance_details_payment'),
          description: t('pages.campers.insurance_details_payment_description') },
        {
          label: t('pages.campers.insurance_details_guarantee'),
          description: t('pages.campers.insurance_details_guarantee_description') },
      ],
      linkText: t('commons.actions.know_more'),
    }
  }

  // YESCAPA INSURANCE
  return {
    title: t('pages.campers.insurance_included'),
    details: [
      {
        label: t('pages.campers.insurance_details_multirisk'),
        description: t('pages.campers.insurance_details_multirisk_description'),
      },
      { label: t('pages.campers.insurance_details_guarantee'), description: t('pages.campers.insurance_details_guarantee_description') },
      isAllianzDe.value
        ? {
            label: t('pages.campers.insurance_details_luggage'),
            description: undefined,
          }
        : null,
      is24_7_insurance.value
        ? {
            label: t('pages.campers.insurance_details_assistance'),
            description: t('pages.campers.insurance_details_assistance_description'),
          }
        : {
            label: t('pages.campers.insurance_details_assistance_alltime'),
            description: t('pages.campers.insurance_details_assistance_description') },
      { label: t('pages.campers.insurance_details_payment'), description: t('pages.campers.insurance_details_payment_description') },
    ].filter(d => d),
    linkText: t('commons.actions.know_more'),
  }
})

const sleepingTypesMaps = computed(() =>
  getReferentialOptions('sleeping_types').reduce((acc: { [key: number]: string }, cur) => {
    acc[cur.value] = cur.text
    return acc
  }, {}))

const camperOptions = computed(() => camper.value?.options
  .filter(({ option_name }) => getReferentialMaps('option_display')[option_name])
  .filter(
    ({ option_name }) =>
      getReferentialMaps('option_display')[option_name][camper.value?.is_pro ? 'is_available_for_pro' : 'is_available_for_individual'],
  ),
)

const { getTranslatedCamperOpenDays } = useGetTranslatedCamperOpenDays()
const openDaysConfig = computed(() => {
  if (!camper.value?.open_days) {
    return null
  }

  const camperOpenDays = camper.value.open_days // have to define this because TS doesn't recognize the narrowing done earlier on camper.value.open_days in the map function
  const allDays: OpenDayKey[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'bkh'] as const
  const weekOrder = allDays.map(key => ({
    value: camperOpenDays[`${key}_is_open`],
    label: getTranslatedCamperOpenDays(key)?.toLowerCase() ?? '', // fallback should never happen but TS kinda requires
  }))

  return {
    open: upperCaseFirstLetter(weekOrder.filter(day => day.value).map(({ label }) => label).join(', ')),
    close: upperCaseFirstLetter(weekOrder.filter(day => !day.value).map(({ label }) => label).join(', ')),
  }
})

const hasWeeklyDiscount = computed(() => {
  if (!camper.value) {
    return null
  }
  return camper.value.discounts_types.includes('weekly_factor')
})

const ownerLanguages = useSpokenLanguagesSentence(camper.value?.vehicle_owner_language_spoke)

const modal = reactive({
  show: false,
})

const { getTranslatedVehicleFuel } = useGetTranslatedVehicleFuel()
const { getTranslatedVehicleGear } = useGetTranslatedVehicleGear()
const modalComponentConfig = computed(() => {
  if (!camper.value || !vehicle.value) return
  const { more = null } = route.query
  switch (more) {
    case moreMap.WISHLIST:
      return {
        component: resolveComponent('AppModalCamperWishlist'),
        props: {
          product: camper.value.id,
        },
      }
    case moreMap.PHOTOS:
      return {
        component: resolveComponent('AppModalFullscreenCamperPhotos'),
        props: {
          pictures: camper.value.picture_set,
        },
      }
    case moreMap.DESCRIPTION:
      return {
        component: resolveComponent('AppModalCamperDescription'),
        props: {
          firstName: camper.value.vehicle_owner_first_name,
          pictureUrl: camper.value.vehicle_owner_picture_url,
          isBestOwner: camper.value.is_ambassador,
          description: vehicle.value.descriptions_default,
          descriptionSourceLanguage: vehicle.value?.descriptions_default_language,
          canTranslateDescription: permissions.value.canTranslateDescription,
          canTranslateOnCreated: !!route.query.translate,
          about: about(),
        },
      }
    case moreMap.INSURANCE:
      return {
        component: resolveComponent('AppModalInsuranceDetails'),
        props: {
          slug: camper.value.insurance_slug,
          idUser: camper.value.vehicle_owner_pk,
          drivingLicenceType: vehicle.value.driving_licence_required,
          is247Insurance: is24_7_insurance.value,
        },
      }
    case moreMap.EQUIPMENTS:
      return {
        component: resolveComponent('AppModalCamperEquipments'),
        props: {
          equipments: vehicle.value.equipments,
        },
      }
    case moreMap.FEATURES:
      return {
        component: resolveComponent('AppModalCamperFeatures'),
        props: {
          features: [
            {
              key: 'brand',
              label: t('pages.campers.features.modal.brand'),
              value: camper.value.title,
            },
            {
              key: 'year',
              label: t('data.vehicle.date.label'),
              value: vehicle.value.year,
            },
            {
              key: 'gvw',
              label: t('data.vehicle.gvw.label'),
              value: vehicle.value.gvw,
              unit: { style: 'unit', unit: 'kilogram' },
            },
            {
              key: 'seat_belts',
              label: t('data.vehicle.seatbelts.label'),
              value: vehicle.value.seatbelts,
            },
            {
              key: 'beds',
              label: t('data.vehicle.beds.label'),
              value: vehicle.value.beds,
            },
            {
              key: 'isofix',
              label: t('data.vehicle.isofix_number.label'),
              value: vehicle.value.isofix_number,
            },
            {
              key: 'fuel',
              label: t('data.vehicle.fuel.label'),
              value: getTranslatedVehicleFuel(vehicle.value.fuel),
            },
            {
              key: 'gear',
              label: t('data.vehicle.gear.label'),
              value: getTranslatedVehicleGear(vehicle.value.gear),
            },
            {
              key: 'consumption',
              label: t('data.vehicle.consumption.label'),
              value: vehicle.value?.consumption
                ? (() => {
                    const referentialValue = getReferentialOptions('consumption_values').find(
                      ({ value }) => vehicle.value?.consumption === value,
                    )?.text
                    return referentialValue
                      ? formatConsumptionEstimate({
                        referentialValue,
                      })
                      : null
                  })()
                : null,
            },
            {
              key: 'fuel_tank',
              label: t('data.vehicle.fuel_tank.label'),
              value: vehicle.value?.fuel_tank,
              unit: { style: 'unit', unit: 'liter' },
            },
            {
              key: 'additive',
              label: t('data.vehicle.additive.label'),
              value: vehicle.value?.additive
                ? getReferentialOptions('additive_types').find(({ value }) => vehicle.value?.additive === value)?.text
                : null,
            },
            {
              key: 'height',
              label: t('data.vehicle.height.label'),
              value: vehicle.value?.height,
              unit: { style: 'unit', unit: 'meter' },
            },
            {
              key: 'long',
              label: t('data.vehicle.long.label'),
              value: vehicle.value?.long,
              unit: { style: 'unit', unit: 'meter' },
            },
            {
              key: 'width',
              label: t('data.vehicle.width.label'),
              value: vehicle.value?.width,
              unit: { style: 'unit', unit: 'meter' },
            },
            {
              key: 'water_tank',
              label: t('data.vehicle.water_tank.label'),
              value: vehicle.value?.water_tank,
              unit: { style: 'unit', unit: 'liter' },
            },
            {
              key: 'used_water_tank',
              label: t('data.vehicle.used_water_tank.label'),
              value: vehicle.value?.used_water_tank,
              unit: { style: 'unit', unit: 'liter' },
            },
          ].map((i): AppModalCamperFeature => ({ ...i, text: '' })).map((item) => {
            if (!item.value) {
              item.text = t('commons.no_information')
            }
            else {
              item.text = item.unit ? n(+item.value, item.unit) : item.value
            }
            return item
          }),
        },
      }
    case moreMap.CAMPER_CANCEL_POLICY:
      return {
        component: resolveComponent('AppModalCamperCancelPolicy'),
        props: {},
      }
  }
  return undefined
})

watch(() => route.query.more, () => {
  if (route.query.more && Object.values(moreMap).includes(route.query.more.toString())) {
    modal.show = true
  }
  else {
    modal.show = false
  }
}, { immediate: true })

const bookingRequestPrices = computed(() => request.value.prices)
const loading = ref(false)

const priceDisplay = computed(() => {
  if (!camper.value || !bookingRequestPrices.value) {
    return
  }
  const count = bookingRequestPrices.value.price.value
  if (count === null || count === undefined) {
    return
  }
  return n(count, { ...currencyMap[bookingRequestPrices.value ? bookingRequestPrices.value.price.currency : camper.value.currency_used] })
})

const splittedPrice = computed(() => {
  if (!camper.value) {
    return []
  }
  const count = bookingRequestPrices.value
    ? Math.floor(bookingRequestPrices.value.price.value / bookingRequestPrices.value.nb_days)
    : camper.value?.starting_price || undefined
  const formatCurrencyPerDay = useFormatCurrencyPerDay()
  const display = formatCurrencyPerDay({
    count,
    currency:
    currencyMap[
      bookingRequestPrices.value ? bookingRequestPrices.value.price.currency : camper.value.currency_used
    ],
    unitDisplay: 'narrow',
    unitOnly: false,
    maxDigit: Number.isInteger(count) ? 0 : 2,
  })

  const i = display.indexOf('/')
  return [display.substring(0, i), display.substring(i)]
})

const searchLink = computed((): RouteLocationRaw | undefined => {
  if (!camper.value || !vehicle.value) {
    return undefined
  }
  const query = getSimilarQuery({
    id: camper.value.id,
    vehicle: vehicle.value,
    date_from: queryDateFrom.value,
    date_to: queryDateTo.value,
  })
  return { name: 's', query }
})

const ownerDashboardLink = computed(() => {
  if (camper.value && camper.value.vehicle_owner_pk === user.value?.id) {
    return { name: 'd-campers-id', params: { id: camper.value.id } }
  }
  return undefined
})

const bookingQueryParameters = computed(() => {
  if (bookingRequestForm.value.dateRange?.start && bookingRequestForm.value.dateRange?.end) {
    return {
      date_from: format(bookingRequestForm.value.dateRange.start, 'yyyy-MM-dd'),
      date_to: format(bookingRequestForm.value.dateRange.end, 'yyyy-MM-dd'),
      hour_from: bookingRequestForm.value.hours?.from,
      hour_to: bookingRequestForm.value.hours?.to,
      kilometers: bookingRequestForm.value.kilometers,
    }
  }
  return {}
})

const setBookingRequestForm = (form: BookingRequestForm) => {
  bookingRequestForm.value = form
  if (!shallowEqual(route.query, bookingQueryParameters.value)) {
    router.replace({ query: bookingQueryParameters.value }) // FIXME navigateTo ?
  }
}

const onClearBookingRequestForm = () => {
  resetPrices()
  setBookingRequestForm({
    dateRange: null,
    hours: {
      from: Constants.BOOKINGS.HOURS.DEPARTURE_MORNING,
      to: Constants.BOOKINGS.HOURS.ARRIVAL_AFTERNOON,
    },
    kilometers: null,
  })
}

const bornedOn = computed(() => {
  if (!isLoggedIn.value || !user.value?.borned_on) {
    return format(sub(new Date(), { years: 23, days: 1 }), 'yyyy-MM-dd')
  }
  return format(new Date(user.value.borned_on), 'yyyy-MM-dd')
})

const refreshBookingRequestPrices = async () => {
  const { DEPARTURE_MORNING, ARRIVAL_AFTERNOON } = Constants.BOOKINGS.HOURS
  const { dateRange, hours, kilometers = 100 } = bookingRequestForm.value
  if (!dateRange || !dateRange.start || !dateRange.end) {
    return
  }

  loading.value = true
  try {
    await fetchPrices({
      id: camperId,
      params: {
        date_from: format(dateRange.start, 'yyyy-MM-dd'),
        date_to: format(dateRange.end, 'yyyy-MM-dd'),
        hour_from: hours?.from as HourFrom ?? DEPARTURE_MORNING as HourFrom,
        hour_to: hours?.to as HourTo ?? ARRIVAL_AFTERNOON as HourTo,
        kilometers: kilometers as KmOption,
        borned_on: bornedOn.value,
        insurance: camper.value?.insurance_id,
      },
    })
  }
  catch (e) {
    if (e instanceof Error) {
      const qualifiedError = $errorManager({ e, name: YSC_API_CAMPER_PRICE_ERROR })
      if (qualifiedError instanceof YscApiCamperPriceError && qualifiedError.fingerprint === CAMPER_PRICE_NOT_AVAILABLE) {
        // refetch availabilities - might have changed since user opened page
        await fetchCurrentCamperAvailabilities()
      }
      if (qualifiedError instanceof YscApiCamperPriceError && qualifiedError.fingerprint === CAMPER_PRICE_INVALID_DOCUMENTS) {
        modalEarlyDeparture.value.show = true
        modalEarlyDeparture.value.hours = qualifiedError.apiErrorCode === BOOKING_PRICE_ERROR_CODES.INVALID_DOCUMENTS_LESS_24 ? 24 : 72
      }
    }
    loading.value = false
    return
  }
  loading.value = false
}

const modalEarlyDeparture = ref({
  show: false,
  hours: 24,
})

const onBookingRequestUpdatePrices = (form: BookingRequestForm) => {
  if (!form.dateRange) {
    onClearBookingRequestForm()
  }

  if (!form.dateRange?.start || !form.dateRange?.end) {
    return
  }

  setBookingRequestForm({
    dateRange: { ...form.dateRange },
    hours: { ...form.hours },
    kilometers: form.kilometers,
  })
  refreshBookingRequestPrices()
}

const firstVehiclePhotoHref = computed(() => {
  if (!vehiclePhotos.value.length) {
    return undefined
  }
  const { seoImageHref } = useSeoImageHref()
  return seoImageHref(vehiclePhotos.value[0].path)
})

onMounted(() => {
  if (!camper.value || !vehicle.value) {
    return
  }
  // Rules https://docs.google.com/spreadsheets/d/12pqwmu3tAkRYO8fJ_Y3cTyQ0z9X51hQV37DYqvPPZhI/edit#gid=1427016546
  const vehicleData: Record<string, any> = {
    id: camper.value.id,
    item_title: title.value,
    final_url: camper.value.ad_url,
    image_url: firstVehiclePhotoHref.value,
    item_subtitle: camper.value.title,
    item_description: getTranslatedVehicleFuel(vehicle.value.fuel),
    item_category: vehicleType.value,
    item_address: vehicleAddress.value,
    city: camper.value.vehicle_location_city,
    latitude: camper.value.vehicle_location_latitude,
    longitude: camper.value.vehicle_location_longitude,
    sleeping_places: camper.value.vehicle_beds,
  }

  if (camper.value.default_price) {
    vehicleData.price = `${camper.value.default_price} ${camper.value.currency_used}`
    vehicleData.formatted_price = t('pages.campers.starting_price_dynamic', {
      price_string: n(camper.value.default_price, {
        ...currencyMap[camper.value.currency_used],
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    })
  }

  if (camper.value.pets_allowed !== Constants.PRODUCTS.OWNER_ACCEPTANCE_STATUS.NOT_ALLOWED) {
    vehicleData.pets = t('data.product.pets_allowed.label')
  }

  const { event } = useGtag()
  // FIXME see with Anthony Roque if the event name is correct and make it as a constant
  event('camper_view', vehicleData)
})

onBeforeMount(() => {
  // reparse date from query on client because JS Date are weird with timezone.
  // Date are implicitly parsed in the system timezone
  // nuxt does not serialize the timezone (just the integer timestamp), so when server and client tz differs
  // it might happen that client date is off by one day.
  // Reparsing on the client side ensure that the actual date used is the same both on client and server
  const { hour_from, hour_to } = route.query
  if (queryDateFrom.value && queryDateTo.value) {
    bookingRequestForm.value.dateRange = {
      start: parse(queryDateFrom.value, 'yyyy-MM-dd', new Date()),
      end: parse(queryDateTo.value, 'yyyy-MM-dd', new Date()),
    }
  }
  if (hour_from && hour_to) {
    bookingRequestForm.value.hours = {
      from: +hour_from,
      to: +hour_to,
    }
  }
})

const showBookingRequestModal = ref(false)

const bookingRequestWidgetProps = computed((): BookingRequestProps | undefined =>
  camper.value
    ? ({
        camperId: camper.value?.id,
        availabilities: availabilities.value,
        bookingMinDays: camper.value.booking_min_length,
        loading: loading.value,
        prices: bookingRequestPrices.value,
        weeklyDiscount: hasWeeklyDiscount.value ?? undefined,
        openDays: camper.value.open_days,
        hasInstantBookingActivated: camper.value.instant_booking_activated,
        hasForcedDepartureAfternoon: camper.value.has_forced_afternoon_departure,
        hasForcedArrivalMorning: camper.value.has_forced_morning_arrival,
        hasForcedArrivalAfternoon: camper.value.has_forced_afternoon_arrival,
        hasHalfDayActivated: camper.value.half_day_activated,
        is200kmFree: camper.value.price_km_200 === 0,
        isUnlimitedKmFree: camper.value.price_km_unlimited === 0,
        isUnlimitedKmActivated: camper.value.unlimited_km_allowed ?? undefined,
        weeklyFactor: camper.value.weekly_factor,
        bookingRequestForm: bookingRequestForm.value,
        maxMonthsFutureRental: camper.value.max_months_future_rental,
        maxDateFutureRental: camper.value.max_date_future_rental,
        minDateFutureRental: camper.value.min_date_future_rental,
      })
    : undefined,
)

const onBookingRequestSubmit = async () => {
  if (!bookingRequestPrices.value) {
    await refreshBookingRequestPrices()
  }

  await navigateTo({ name: 'f-campers-id_camper-request', params: { id_camper: route.params.id }, query: bookingQueryParameters.value })
}

const isCamperDeleted = computed((): boolean => {
  if (!camper.value) {
    return true
  }
  const { DELETED, DELETED_OWNER } = Constants.PRODUCTS.STATUS
  return ([DELETED, DELETED_OWNER] as CamperState[]).includes(camper.value.state)
})

const assets = getDynamicAssetsMap(import.meta.glob('~/assets/img/payments/*.svg', { eager: true })) as Record<string, string>

const payments = computed(() => {
  const paymentMethod: Record<string, string[]> = {
    card: ['mastercard', 'visa'],
    ho_vou: ['ancv'],
    sofort: ['klarna'],
    paypal: ['paypal'],
    klarna: ['klarna'],
    ideal: ['ideal'],
    mbway: ['mbway'],
  }
  const options: string[] = []

  referential.value?.payment_type_available?.forEach((value) => {
    const mappedValue = paymentMethod[value]
    if (!mappedValue || mappedValue.find(v => options.includes(v))) {
      return
    }
    options.push(...mappedValue)
  })

  return options
})

// Intersection observers
const reviewsEl = ref()
const reviewElIsVisible = ref(false)

const { stop: stopReviewsObserver } = useIntersectionObserver(
  reviewsEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      reviewElIsVisible.value = true
      stopReviewsObserver()
    }
  },
  {
    rootMargin: '200px 0px 0px 0px',
  },
)

const similarsEl = ref()
const isSimilarsVisible = ref(false)
const { stop: stopSimilarsObserver } = useIntersectionObserver(
  similarsEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isSimilarsVisible.value = true
      stopSimilarsObserver()
    }
  },
  {
    rootMargin: '200px 0px 0px 0px',
  },
)

const bookingRequestWidgetDesktopRootEl = ref()
const bookingRequestWidgetDesktopEl = ref()
const isbookingRequestWidgetDesktopVisible = ref(false)
const bookingRequestWidgetMobileEl = ref()
const isbookingRequestWidgetMobileVisible = ref(false)

useIntersectionObserver(
  bookingRequestWidgetDesktopEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isbookingRequestWidgetDesktopVisible.value = true
      isbookingRequestWidgetMobileVisible.value = false
    }
  }, {
    root: bookingRequestWidgetDesktopRootEl,
  },
)

useIntersectionObserver(
  bookingRequestWidgetMobileEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isbookingRequestWidgetMobileVisible.value = true
      isbookingRequestWidgetDesktopVisible.value = false
    }
  },
)

// SEO and meta tags
const seoTitle = computed(() => t('pages.campers.title_dynamic', {
  type_string: vehicleType.value,
  location_string: camper.value?.vehicle_location_city,
  vehicle_string: camper.value?.title,
  id_string: `${camper.value?.id}`,
}))
const seoDescription = computed(() => vehicle.value?.descriptions_default?.length ? vehicle.value?.descriptions_default.replace(/<[^>]+>/g, '') : '')
useSeoText({ title: seoTitle, description: seoDescription })
useSeoImage({ imagePath: firstVehiclePhotoHref.value })

// Prevent microdata injection if camper don't have review yet otherwise it throws errors in Google Search Console
if (owner.value?.reviews.as_owner.count && owner.value?.reviews.as_owner.average) {
  const camperMicrodata = computed (() => {
    const microdata: Product = {
      '@type': 'Product',
    }

    if (title.value) {
      microdata.name = title.value
    }
    if (firstVehiclePhotoHref.value) {
      microdata.image = firstVehiclePhotoHref.value
    }

    if (vehicle.value?.descriptions_default_language) {
      microdata.description = vehicle.value.descriptions_default_language
    }

    if (vehicle.value?.manufacturer?.name) {
      microdata.brand = vehicle.value.manufacturer.name
    }

    microdata.aggregateRating = {
      '@type': 'AggregateRating',
      'bestRating': 5,
      'worstRating': 0,
      'ratingValue': owner.value?.reviews.as_owner.average,
      'reviewCount': owner.value?.reviews.as_owner.count,
    }

    return microdata
  })

  useHead({
    script: [
      getJsonLDScript(camperMicrodata.value),
    ],
  })
}
</script>

<template>
  <div class="border-t">
    <!-- <component
      :is="marketingPartnershipConfig.component"
      v-if="marketingPartnershipConfig.component"
      v-bind="marketingPartnershipConfig.props"
      class="border-b border-gray-200"
    /> -->
    <template v-if="camper && owner">
      <div class="container">
        <YscBreadcrumbs
          :crumbs="crumbs"
          class="hidden sm:block"
        >
          <template #default="{ crumb }">
            <NuxtLink
              :to="crumb.to"
              :external="crumb.external"
              class="focus-visible:ring-peacock-500 font-light hover:underline focus-visible:rounded focus-visible:ring-2 group-last:font-semibold"
              itemprop="item"
            >
              <span itemprop="name">
                {{ crumb.name }}
              </span>
            </NuxtLink>
          </template>
        </YscBreadcrumbs>
        <AppCamperHero
          :vehicle-photos="vehiclePhotos"
          :is-new="camper.is_new"
          :review-count="owner.reviews.as_owner.count"
          :review-average="owner.reviews.as_owner.average"
          :show-wishlist="isLoggedIn"
          :address-link="useGetRouteWith({ hash: '#address' })"
          :address-text="vehicleAddress"
          :is-in-wishlist="isCamperInWishlist(camper.id)"
          :title="title ?? seoTitle"
          :vehicle-brand="vehicle?.brand ?? null"
          @click-add-wishlist="navigateTo(useGetRouteWith({ query: { more: moreMap.WISHLIST } }))"
          @click-see-all-photos="seeAllPhotos"
          @click-see-reviews="navigateTo(useGetRouteWith({ hash: '#reviews' }))"
        />

        <div class="relative mx-auto max-w-screen-xl lg:grid lg:grid-cols-3 lg:gap-x-16">
          <div class="space-y-10 sm:space-y-20 lg:col-span-2">
            <AppCamperEmphasisSummaryList
              :camper="camper"
              :vehicle="vehicle"
              :verified-documents="verifiedDocuments"
              :instant-booking-activated="camper.instant_booking_activated"
            />

            <AppCamperInsuranceDescription
              :title="insuranceBlock.title"
              :link-query-more="moreMap.INSURANCE"
              :insurance-block="insuranceBlock"
              :no-insurance="noInsurance"
            />

            <AppCamperDescription
              :camper="camper"
              :vehicle="vehicle"
              :owner="owner"
              :can-show-more-description="permissions.canShowMoreDescription"
              :link-query-more="moreMap.DESCRIPTION"
            />

            <div class="space-y-8">
              <YscHeading level="2">
                {{ $t('pages.campers.properties') }}
              </YscHeading>

              <AppCamperSectionDisplay :heading-title="$t('commons.sleepings')">
                <p
                  v-if="!vehicle?.sleepings.length"
                  class="italic"
                >
                  {{ $t('commons.no_information') }}
                </p>
                <div
                  v-if="vehicle?.sleepings.length"
                  class="grid grid-cols-2 md:grid-cols-3 px-4 gap-8"
                >
                  <YscSleepings
                    v-for="(sleeping, i) in vehicle.sleepings"
                    :key="sleeping.id"
                    :index="i + 1"
                    :height="sleeping.height"
                    :width="sleeping.width"
                    :type="sleeping.type"
                  >
                    {{ sleepingTypesMaps[sleeping.type] }}
                  </YscSleepings>
                </div>
              </AppCamperSectionDisplay>
              <AppCamperSectionDisplay :heading-title="$t('commons.equipments')">
                <AppCamperDisplayedEquipments
                  v-if="vehicle"
                  :vehicle="vehicle"
                  :link-query-more="moreMap.EQUIPMENTS"
                />
              </AppCamperSectionDisplay>

              <AppCamperSectionDisplay :heading-title="$t('pages.campers.features.title')">
                <AppCamperFeaturesOverview
                  v-if="vehicle"
                  :camper="camper"
                  :vehicle="vehicle"
                  :link-query-more="moreMap.FEATURES"
                />
              </AppCamperSectionDisplay>
            </div>

            <div class="space-y-8">
              <YscHeading level="2">
                {{ $t('pages.campers.conditions') }}
              </YscHeading>

              <AppCamperSectionDisplay :heading-title="$t('pages.campers.owner_conditions.title')">
                <AppCamperConditionOverview
                  v-if="vehicle"
                  :camper="camper"
                  :vehicle="vehicle"
                />
              </AppCamperSectionDisplay>

              <AppCamperSectionDisplay :heading-title="$t('data.product.deposit.label')">
                <AppCamperDeposit :camper="camper" />
              </AppCamperSectionDisplay>

              <AppCamperSectionDisplay :heading-title="$t('pages.campers.cancel_policy')">
                <p>
                  {{ $t('pages.campers.cancel_policy_description') }}
                </p>

                <div>
                  <NuxtLink
                    :to="useGetRouteWith({ query: { ...route.query, more: moreMap.CAMPER_CANCEL_POLICY } })"
                    class="link link-primary"
                  >
                    {{ $t('pages.campers.see_camper_policy') }}
                  </NuxtLink>
                </div>
              </AppCamperSectionDisplay>

              <AppCamperSectionDisplay
                v-if="camperOptions && camperOptions.length"
                :heading-title="$t('pages.campers.options')"
              >
                <ul class="grid gap-x-4 gap-y-2 sm:grid-cols-2">
                  <li
                    v-for="opt in camper.options"
                    :key="`opt-${opt.id}`"
                  >
                    <span class="font-semibold">
                      {{ getReferentialMaps('option_display')[opt.option_name].name }}
                    </span>
                    <span class="block">
                      {{ $n(opt.price, currencyMap[camper.currency_used]) }}
                      {{ getTranslatedCamperOptionRecurrence(opt.recurrence) }}
                    </span>
                  </li>
                </ul>
              </AppCamperSectionDisplay>
            </div>

            <div
              id="address"
              class="pt-12"
            >
              <YscHeading level="2">
                {{ $t('pages.campers.location') }}
              </YscHeading>
              <hr class="my-8">
              <div>
                <span class="flex items-center font-semibold">
                  <YscIconsMarker class="h-6 w-6 mr-1.5" />{{ vehicleAddress }}</span>

                <p class="text-gray-500 mt-1 mb-4">
                  {{ $t('pages.campers.location_info') }}
                </p>
              </div>

              <YscMapStatic
                v-if="vehicle?.location.latitude && vehicle?.location.longitude"
                :lat="vehicle.location.latitude"
                :lng="vehicle.location.longitude"
                :zoom="10"
                aspect="aspect-video rounded lg:aspect-[2/1] overflow-hidden"
                with-marker
              />
            </div>

            <ul
              v-if="camper.open_days"
              class="grid sm:grid-cols-2 gap-4"
            >
              <li class="flex items-start gap-x-3">
                <YscIconsSignOpen class="h-6 w-6" />
                <div class="flex flex-col">
                  <span class="font-semibold">{{ $t('components.app_product_form_opendays.title') }}</span>
                  <span>{{ openDaysConfig?.open }}</span>
                </div>
              </li>
              <li
                v-if="openDaysConfig?.close.length"
                class="flex items-start gap-x-3"
              >
                <YscIconsSignClose class="h-6 w-6" />
                <div class="flex flex-col">
                  <span class="font-semibold">{{ $t('pages.campers.closed_days') }}</span>
                  <span>{{ openDaysConfig?.close }}</span>
                </div>
              </li>
            </ul>

            <div>
              <div
                id="reviews"
                ref="reviewsEl"
                class="py-12"
              >
                <i18n-t
                  keypath="pages.campers.guest_reviews_plural_dynamic"
                  tag="h2"
                  scope="global"
                  class="text-xl md:text-2xl"
                >
                  <template #emphasis_string>
                    <span class="font-semibold">
                      {{ $t('pages.campers.guest_reviews_plural_emphasis', { count_number: owner.reviews.as_owner.count }, owner.reviews.as_owner.count || 0) }}

                    </span>
                  </template>
                </i18n-t>
                <hr class="my-8">

                <AppCamperReviews
                  v-if="reviewElIsVisible"
                  :owner-id="camper.vehicle_owner_pk"
                  :owner-first-name="camper.vehicle_owner_first_name"
                  :vehicle-type="vehicle?.type"
                  :camper-id="camper.id"
                  :owner-review-average="owner.reviews.as_owner.average"
                  :owner-review-count="owner.reviews.as_owner.count"
                />
              </div>

              <div class="border border-gray-200 rounded-md divide-y divide-gray-200 mt-12">
                <div class="px-4 lg:px-24 flex flex-col space-y-2 pt-10 pb-8 items-center">
                  <YscAvatar
                    :first-name="camper.vehicle_owner_first_name"
                    :picture="camper.vehicle_owner_picture_url"
                    radius="40"
                  />
                  <span class="text-3xl font-semibold">{{ camper.vehicle_owner_first_name }}</span>
                  <span
                    v-if="camper.is_ambassador"
                    class="inline-flex items-center gap-x-1.5"
                  >
                    <YscIconsMedal class="h-5 w-5" />
                    <span class="font-semibold">{{ $t('commons.best_owner') }}</span>
                  </span>

                  <span>
                    {{
                      owner.professional
                        ? $t('pages.campers.owner_card.owner_pro', { registration_year: $d(new Date(owner.joined_at), { year: 'numeric' }) })
                        : $t('pages.campers.owner_card.owner_private', { registration_year: $d(new Date(owner.joined_at), { year: 'numeric' }) })
                    }}
                  </span>

                  <template v-if="camper.is_ambassador">
                    <i18n-t
                      class="text-gray-500 text-sm text-center"
                      keypath="pages.campers.owner_card.best_owner_dynamic"
                      tag="span"
                      scope="global"
                    >
                      <template #value_string>
                        {{ camper.vehicle_owner_first_name }}
                      </template>
                      <template #emphasis_best_review_string>
                        <span class="font-semibold">{{ $t('pages.campers.owner_card.emphasis_best_review_string') }}</span>
                      </template>
                      <template #emphasis_relation_string>
                        <span class="font-semibold">{{ $t('pages.campers.owner_card.emphasis_relation_string') }}</span>
                      </template>
                    </i18n-t>
                  </template>
                  <i18n-t
                    v-else
                    class="text-center"
                    tag="span"
                    scope="global"
                    keypath="pages.campers.owner_card.joined_at_emphasis"
                  >
                    <template #emphasis_string>
                      <span class="font-semibold">{{ $t('pages.campers.owner_card.signed_up') }}</span>
                    </template>
                    <template #value_string>
                      {{ $d(new Date(owner.joined_at), {
                        year: 'numeric',
                      }) }}
                    </template>
                  </i18n-t>
                </div>
                <p
                  v-if="owner.description"
                  class="px-4 py-3 lg:px-8 lg:py-6"
                >
                  {{ owner.description }}
                </p>
                <ul
                  class="grid px-4 py-3 lg:px-8 lg:py-6 gap-3 lg:gap-4 sm:grid-cols-2"
                  :class="camper.is_owner_id_certified ? 'md:grid-cols-4' : 'md:grid-cols-3'"
                >
                  <li class="flex flex-col">
                    <span class="font-semibold">{{ $t('pages.campers.owner_card.review_average') }}</span>
                    <div
                      v-if="owner.reviews.as_owner.average"
                      class="flex items-center"
                    >
                      <YscIconsStarSolid class="inline-block h-4 w-4 text-pink-500 mr-1.5" />
                      {{ $n(owner.reviews.as_owner.average) }}/5
                    </div>
                    <div v-else>
                      <AppBadgeNew />
                    </div>
                  </li>
                  <li class="flex flex-col">
                    <span class="font-semibold">{{ $t('pages.campers.owner_card.answer_rate') }}</span>
                    <span>
                      {{ $n(owner.answer_rate / 100, 'percent') }}
                    </span>
                  </li>
                  <li class="flex flex-col">
                    <span class="font-semibold">{{ $t('data.user.language_spoke.label') }}</span>
                    <span>{{ ownerLanguages.length ? ownerLanguages : $t('commons.no_information') }}</span>
                  </li>
                  <li
                    v-if="camper.is_owner_id_certified"
                    class="flex flex-col"
                  >
                    <YscIconsCheckCircleSolid class="w-5 h-5 text-peacock-500" />
                    <span>{{ $t('pages.campers.owner_card.verified') }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            ref="bookingRequestWidgetDesktopRootEl"
            class="hidden lg:sticky lg:top-6 lg:mt-12 lg:block"
          >
            <div
              ref="bookingRequestWidgetDesktopEl"
              class="sticky top-6 space-y-10"
            >
              <div class="rounded border border-gray-200 bg-gray-50">
                <div
                  v-if="camper.instant_booking_activated"
                  class="flex items-center space-x-3 px-4 py-3 border-b rounded-t border-gray-200 bg-white"
                >
                  <YscIconsFlashSolid class="h-6 w-6 text-yellow-500" />
                  <div>
                    <span class="font-semibold block">
                      {{ $t('commons.instant_booking') }}
                    </span>
                    <span>
                      {{ $t('components.app_camper_booking_request_widget.instant_booking_tip.label') }}
                    </span>
                  </div>
                </div>
                <div
                  v-else
                  class="flex items-center space-x-3 px-4 py-3 border-b rounded-t border-gray-200 bg-white"
                >
                  <div>
                    <YscIconsConversationChatBubble class="h-6 w-6 text-pink-500" />
                  </div>
                  <span>
                    {{ $t('components.app_camper_booking_request_widget.no_obligation') }}
                  </span>
                </div>
                <div class="border-b border-gray-200 px-4 py-4">
                  <i18n-t
                    v-if="!bookingRequestPrices"
                    keypath="pages.campers.starting_price_dynamic"
                    tag="p"
                    class="text-xl"
                    scope="global"
                  >
                    <template #price_string>
                      <span class="text-2xl font-semibold">{{ splittedPrice[0] }}</span>{{ splittedPrice[1] }}
                    </template>
                  </i18n-t>
                  <template v-else>
                    <span class="text-2xl font-semibold">{{ splittedPrice[0] }}</span>{{ splittedPrice[1] }}
                  </template>
                </div>
                <AppBookingRequestWidgetDisabled
                  v-if="camper.accepted_requests_limit_reached || isCamperDeleted"
                  :title="$t('components.app_camper_booking_request_widget.booking_request_disabled')"
                  :subtitle="$t('components.app_camper_booking_request_widget.waiting_for_documents')"
                  :cta-link="searchLink"
                  :cta-label="$t('components.app_camper_booking_request_widget.find_others_cta')"
                />
                <AppBookingRequestWidgetDisabled
                  v-else-if="!camper.published"
                  :title="$t('components.app_camper_booking_request_widget.camper_not_published')"
                  :cta-link="ownerDashboardLink"
                  :cta-label="$t('components.app_camper_booking_request_widget.complete_my_camper')"
                />
                <HoBookingRequestWidget
                  v-else-if="isbookingRequestWidgetDesktopVisible && bookingRequestWidgetProps"
                  v-bind="bookingRequestWidgetProps"
                  class="p-4"
                  @submit="onBookingRequestSubmit"
                  @update-prices="onBookingRequestUpdatePrices"
                  @clear-form="onClearBookingRequestForm"
                />
              </div>
              <div
                v-if="bookingRequestPrices && bookingRequestPrices.price_2x_first?.value"
                class="rounded border border-gray-200 bg-white p-4"
              >
                <div class="flex items-center gap-3">
                  <div>
                    <YscIconsPaymentTwoTimes
                      class="h-6 w-6 grow"
                      highlight="text-pink-500"
                    />
                  </div>
                  <i18n-t
                    keypath="pages.campers.pay_cb2x_dynamic"
                    tag="p"
                    scope="global"
                    class="text-sm text-gray-500"
                  >
                    <template #emphasis_string>
                      <span class="text-pink-500">
                        {{ $t('pages.campers.pay_cb2x_emphasis') }}
                      </span>
                    </template>
                  </i18n-t>
                </div>
              </div>

              <div
                v-if="payments.length"
                class="flex flex-wrap justify-center gap-4"
              >
                <img
                  v-for="file in payments"
                  :key="file"
                  :src="assets[file]"
                  class="rounded border"
                  :alt="file"
                  loading="lazy"
                  width="48"
                  height="32"
                >
              </div>
            </div>
          </div>

          <component
            :is="modalComponentConfig.component"
            v-if="modal.show && modalComponentConfig?.component"
            v-bind="modalComponentConfig.props"
            @close="navigateTo(useGetRouteWith({ query: { ...route.query, more: null, translate: null } }))"
          />
          <AppModalDocumentEarlyDeparture
            v-if="modalEarlyDeparture.show"
            :hours="modalEarlyDeparture.hours"
            @close="modalEarlyDeparture.show = false"
          />
          <HoBookingRequestModal
            v-if="showBookingRequestModal && bookingRequestWidgetProps"
            v-bind="bookingRequestWidgetProps"
            form-id="booking-request-form-modal"
            @close="showBookingRequestModal = false"
            @submit="onBookingRequestSubmit"
            @update-prices="onBookingRequestUpdatePrices"
            @clear-form="onClearBookingRequestForm"
          />
        </div>

        <div
          id="similar"
          ref="similarsEl"
          class="pt-12 pb-24 border-t mt-32"
        >
          <YscHeading
            level="2"
            class="md:text-center"
          >
            {{ $t('pages.campers.similars_dynamic', { location_string: vehicle?.location.city }) }}
          </YscHeading>

          <AppCamperSimilars
            v-if="vehicle"
            :camper-id="camper.id"
            :vehicle="vehicle"
          />
        </div>
      </div>
      <div
        ref="bookingRequestWidgetMobileEl"
        class="sticky bottom-0 left-0 right-0 z-20 bg-gray-100 lg:hidden"
      >
        <AppBookingRequestWidgetDisabled
          v-if="camper.accepted_requests_limit_reached"
          :title="$t('components.app_camper_booking_request_widget.booking_request_disabled')"
          :subtitle="$t('components.app_camper_booking_request_widget.verifying_documents')"
          :cta-link="searchLink"
          :cta-label="$t('components.app_camper_booking_request_widget.find_others_cta')"
        />
        <AppBookingRequestWidgetDisabled
          v-else-if="isCamperDeleted"
          :title="$t('components.app_camper_booking_request_widget.booking_request_disabled')"
        />
        <AppBookingRequestWidgetDisabled
          v-else-if="!camper.published"
          :title="$t('components.app_camper_booking_request_widget.camper_not_published')"
          :cta-link="ownerDashboardLink"
          :cta-label="$t('components.app_camper_booking_request_widget.complete_my_camper')"
        />
        <AppBookingRequestWidgetFooter
          v-else-if="isbookingRequestWidgetMobileVisible"
          :splitted-price="splittedPrice"
          :price="priceDisplay"
          :selected-dates="bookingRequestForm.dateRange"
          :loading="loading"
          form-id="booking-request-form-modal"
          class="container border-t bg-white py-4"
          @submit-invisible-form="showBookingRequestModal = !showBookingRequestModal"
        />
      </div>
    </template>
  </div>
</template>

<style>
.vehicle-description--wrapper {
  & * {
    @apply no-underline;
  }
  /* safari: line-clamp does not work on not inline elements */
  & * {
    @apply inline;
  }
}
</style>
